

@mixin can-toggle-branding(
  $can-toggle-off-color: white, 
  $can-toggle-on-color: #5fc054,
  $can-toggle-inactive-text: #BEBEBE,
  $can-toggle-transition: cubic-bezier(0,1,0.5,1)
) {
  
  input[type="checkbox"] {
    
    &[disabled] ~ label {
      color: rgba($can-toggle-off-color, 0.5);
    }
    
    &:focus ~ label {
      .can-toggle__switch {
        background-color: white;
        &:after { color: white; }
      }
    }
    &:checked {
      ~ label {
        
        .can-toggle__switch {
          &:after { color: white }
        }
      }
      
    }
  }
  
  label {
    
    .can-toggle__label-text { flex: 1; }
    
    .can-toggle__switch {
      transition: background-color 0.3s $can-toggle-transition;
      background: white;
      border: 1px solid #CFD4D9;
      &:before { color: #BEBEBE; }
      &:after {
        -webkit-transition: -webkit-transform 0.3s $can-toggle-transition;
        transition: transform 0.3s $can-toggle-transition;
        color: $can-toggle-off-color; 
      }
    }
    
  }
}

@mixin can-toggle-appearance 
( 
  $can-toggle-width: 80px,
  $can-toggle-height: 36px,
  $can-toggle-border-radius: 4px,
  $can-toggle-offset: 2px,
  $can-toggle-label-font-size: 14px,
  $can-toggle-switch-font-size: 12px,
  $can-toggle-shadow: 0 3px 3px rgba(black, 0.4)
){
  $can-toggle-switch-width: $can-toggle-width/2;
  
  input[type="checkbox"] {

    &:focus ~ label {
      
    }
    
    &:checked {
      ~ label {
        .can-toggle__switch {
          &:after { transform: translate3d($can-toggle-width - ($can-toggle-switch-width + $can-toggle-offset),0,0); }  
        }
      }
      &:focus {
        ~ label {
        } 
      }
    }
  }
  
  label {
    font-size: $can-toggle-label-font-size;
    
    .can-toggle__switch {
      height: 38px;
      flex: 0 0 $can-toggle-width;
      border-radius: $can-toggle-border-radius;
      
      &:before {
        left: $can-toggle-width/2;
        font-size: $can-toggle-switch-font-size; 
        line-height: $can-toggle-height;
        width: 40px;
        padding: 0 12px;
      }
      
      &:after {
        top: 0; 
        left: 0;
        border-radius: $can-toggle-border-radius/2;
        width: 40px; 
        line-height: $can-toggle-height - ($can-toggle-offset*2);
        font-size: $can-toggle-switch-font-size;
      }
      
      

    } 
  }
}



.can-toggle {
  position: relative;
  width: 80px;
  *, *:before, *:after { box-sizing: border-box; }
  
  
  input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0; left: 0;
    
    &[disabled] ~ label {
      pointer-events: none;
      .can-toggle__switch { opacity: 0.4; }
    }
    
    &:checked {
      ~ label {
        
        .can-toggle__switch {
          
          &:before {
            content: attr(data-unchecked);
            left: 0;
          }
        
          &:after {
            content: attr(data-checked);
          }  
        }
      }
      
      
    }
  }
  
  label {
    user-select: none;
    position: relative;
    display: flex;
    align-items: center;
    
    .can-toggle__label-text { 
      flex: 1;
      padding-left: 32px;
    }
    
    .can-toggle__switch {
      position: relative;
      
      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 0;
        text-transform: uppercase;
        text-align: center;
      }
      
      &:after {
        content: attr(data-unchecked);
        position: absolute;
        z-index: 5;
        text-transform: uppercase;
        text-align: center;
        background: #2FB672;
        color: white;
        transform: translate3d(0,0,0);
      }

    }
    
  }
  

  @include can-toggle-branding; 
  @include can-toggle-appearance; 
  
   &.can-toggle--size-small {
    @include can-toggle-appearance
    (
      90px, 
      28px,  
      2px,   
      1px,   
      13px,  
      10px,  
      0 2px 2px rgba(black, 0.4) 
    ); 
  }
  
  &.can-toggle--size-large {
    @include can-toggle-appearance
    (
      160px, 
      50px,  
      4px,   
      2px,   
      14px,  
      14px,  
      0 4px 4px rgba(black, 0.4) 
    ); 
  }
  
  
  &.demo-rebrand-2 {
    @include can-toggle-branding
    (
      white, 
      #69be28, 
      rgba(white, 0.7),  
      cubic-bezier(0.860, 0.000, 0.070, 1.000)  
    );
    @include can-toggle-appearance
    (
      80px,
      40px,  
      60px,  
      2px,   
      13px, 
      13px, 
      0 4px 4px rgba(black, 0.4) 
    ); 
  }
  
}