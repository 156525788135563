@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-BlackItalic.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-Black.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-BoldItalic.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-Light.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-LightItalic.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-BookItalic.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-BookItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-Bold.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-Book.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-Regular.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-Medium.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-MediumItalic.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-RegularItalic.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-Thin.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-SemiBold.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-ThinItalic.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-SemiBoldItalic.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-UltraLightItalic.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-UltraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-UltraBold.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-UltraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-UltraLight.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-UltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mena Grotesk';
    src: url('./assets/fonts/MenaGrotesk/MenaGrotesk-UltraBoldItalic.woff2') format('woff2'),
        url('./assets/fonts/MenaGrotesk/MenaGrotesk-UltraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
.h-nofound {
    height: calc(100vh - 250px );
}
@media (min-width: 1536px) {
    .container {
        max-width: 1280px!important;
    }
}

.toasts-wrapper {
    position: fixed;
    top: 100px;
    right: 20px;
  }
  
  .toast {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, .2);
    position: relative;
  }

  .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
    background-color: #2FB672!important;
    color: white;
    font-weight: 600;
  }
  .MuiButtonBase-root.MuiPaginationItem-root {
    color: #303030;
    font-weight: 500;
  }


  @tailwind base;
@tailwind components;
@tailwind utilities;