.toast {
    width: 500px;
    padding: 15px 20px;
    border-radius: 4px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    &[data-variation="success"] {
        background-color: #2FB672;
        color: white;
    }
    &[data-variation="error"] {
        background-color: #F3D8DB;
        border: 1px solid #EDC4C8;
        color: #7A282B;
    }

    @media (max-width: 768px){
        width: 100%;
    }
}