.input-search {
  border-radius: 10px;
  padding: 15px 15px 15px 25px;
  width: 100%;
  border-color: transparent;

  @media (max-width: 768px) {
      padding: 8px;
  }

  &:focus {
    outline: 1px solid #e0e0e0;
  }
  
}

.filter-link {
  text-transform: initial !important;
    color: #212121 !important;
    font-size: 16px;
    line-height: 24px !important;
    font-family: "Mena Grotesk" !important;
  .gg-chevron-down {
    margin-left: 8px;
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 18px;
    height: 18px;
    border: 2px solid transparent;
    border-radius: 100px;
}
} 

.suggestion-list {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 1px solid #f8f8f8;
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1),
    0px 10px 10px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  max-height: 366px;
  overflow-y: auto;
  padding: 10px 10px;
}

.suggestion {
  padding: 8px 10px;
  letter-spacing: -0.32px;
  cursor: pointer;
  border-radius: 10px;
  pointer-events: all!important;
  touch-action: auto;

  &:hover,
  &:focus {
    background-color: #d5f0e3;
  }

  &.selected {
    background-color: #d5f0e3;
  }
}

.select-order {
  .dropdown {
      position: relative;
      display: inline-block;
      height: 100%;
    }
    
    .dropdown-selected {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 150px;
      padding: 8px;
      cursor: pointer;
      color: #5F5F5F;
      font-size: 16px;
      border-radius: 100px;
      text-align: center;
      
      &:hover {
        background-color: #f2f2f2;
      }

      @media (max-width: 768px) {
          width: auto;
          display: none;
      }
    }

    
    .dropdown-options {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      padding: 0;
      margin: 0;
      background-color: #fff;
      border: 1px solid #F8F8F8;
      box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
      border-radius: 40px;
      border-top: none;
      list-style: none;
      z-index: 1;
      padding: 10px 10px;

      @media (max-width: 768px) {
          width: 150px;
          margin-left: -55px;
      }
    
      & .dropdown-option {
        height: 40px;
        padding: 8px;
        cursor: pointer;
        border-radius: 100px;
        color: #5F5F5F;
        font-size: 16px;
    
        &:hover {
          background: #F5F5F5;
        }
    
        &.selected {
          background: #F5F5F5;
        }
      }
    }
}


.filter-container {
  display: flex;
  justify-content: start;
  gap: 8px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: auto;

    .option-filter {
      flex-basis: auto;
      flex-grow: 0;
      flex-shrink: 0;
      max-width: auto;
      width: auto;
    }
  }
}