.MuiTooltip-popper {
    .MuiTooltip-tooltip {
        background: #222;
        opacity: 0.9;
        font-weight: 400;
        color: white;
        width: 300px;
        padding: 8px 16px;
        border-radius: 3px;
        font-size: 90%;
        pointer-events: none;
        font-family: "Nunito";

        .MuiTooltip-arrow {
            color: #222;
        }
    }
}

