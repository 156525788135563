.card {
  background: transparent;
  border-radius: 12px;
  position: relative;
  &__content {
      color: #5F5F5F;
  }

  &__image {
      height: 280px;
      img {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          
          width: 100%;
      }
      .bt-bi {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      
      
  }

  .over-available {
    border-radius: 10px;
    z-index: 1;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);

    .message {
      background-color: #2FB672;
      padding: 10px 5px;
      border-radius: 10px;
      color: white;
      margin-top: 55px;
      margin-left: auto;
      margin-right: auto;
      width: 80%;
    }
  }

  .promo-tag {
    position: absolute;
    z-index: 1;
    left: -10px;
    top: 15px;
  }

  .property-gallery-card {
      position: relative;
      .main {
        height: 100%;
        overflow-x: hidden;
        transition: transform 0.3s ease;
        img {
          height: 100%;
          object-fit: cover;
          object-position: center;
          width: 100%;
        }
    
        .options {
          bottom: 0;
          left: 5%;
        }
      }
      .previous,
      .next {
        position: absolute;
        z-index: 1;
        height: 30px;
        width: 30px;
        display: none;

        @media (max-width: 768px) {
          display: block;
        }
      }

      &:hover {
        .previous, .next {
          display: block;
        }
      }

      .next {
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
      }
      .previous {
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
      }
    }
}


.showPromo {
  display: block!important;
}
.notShowPromo {
  display: none!important;
}