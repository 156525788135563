.collapseItem {
    overflow: hidden;
    transition: height 0.3s;
  
  
    .collapse-header {
      cursor: pointer;
      font-family: 'Nunito', sans-serif;
      color: #212121;
        font-weight: 500;
    }
  
    .collapse-content {
      padding: 10px;
    }
  }
  