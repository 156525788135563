.container-form {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
}
.divider-login {
  width: 100%;
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  border: none;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  padding: 24px 0 0;
  padding: calc(8px*3) 0 0 0;

  &::before {
    content: "";
    border-bottom: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    flex: 1 0 auto;
    height: 0.5em;
    margin: 0;
  }
  &::after {
    content: "";
    border-bottom: 1px solid #c2c8d0;
    border-bottom: 1px solid #E0E0E0;
    flex: 1 0 auto;
    height: 0.5em;
    margin: 0;
  }
  span {
    text-align: center;
    flex: 0.2 0 auto;
    margin: 0;
  }
}
