.modal {
    position: fixed;
    z-index: 50;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    animation-name: animateFade;
    animation-duration: 0.2s;
    background-color: rgba(0, 0, 0, 0.5);
    @media (max-width: 768px) {
        position: fixed;

    }
    .modal_content {
        max-width: 500px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: relative;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        @media (max-width: 768px) {
            padding: 0px 10px;
        }
        .modal_body {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            @media (max-width: 768px) {
                height: 500px;
                overflow-y: auto;
            }
        }
    }
}


.modal-complete {
    position: fixed;
    z-index: 10000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    animation-name: animateFade;
    animation-duration: 0.2s;
    background-color: white;

    .modal_content {
        .modal_body {
            height: 95vh;
            overflow-y: auto;
        }
    } 
}