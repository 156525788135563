.card_offer {
  background: #ffffff;
  
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  &[data-variation="accepted"] {
    background-color: white;
    box-shadow: rgba(38, 146, 91, 0.5) 0px 4px 12px;
    p,
    button,
    a {
    }

    .current_gray {
      background-color: transparent;
    }
  }
  a {
    color: #252b5c;
  }

  &__image {
    position: relative;
    img {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .overlay {
      background: #000;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.6573223039) 100%
      );
      padding: 16px 16px;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  &__info {
    font-weight: 400;
    font-size: 12.8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .current_gray {
      background-color: #f8faf7;
      border-radius: 5px;
      padding: 12px 20px;
    }
    .current_green {
      background-color: #d5f0e3;
      border-radius: 5px;
      padding: 12px 20px;
    }
  }
}
