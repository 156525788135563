.search-mobile {
  .title-label {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.32px;
    margin-bottom: 5px;

    /* Grey - Soft 2/800 */

    color: #5f5f5f;
  }
  .search-input {
    background: #f5f5f5;
    border-radius: 100px;
    padding: 15px;
    width: 100%;
  }

  .suggestion-list {
    position: sticky;

    .suggestion {
      pointer-events: all;
      touch-action: auto;
    }
  }

  .options-list-mobile {
    height: auto !important;
    overflow-y: auto;
    .suggestion {
      pointer-events: all;
      touch-action: auto;
      color: #5f5f5f;
      font-weight: 500;
      font-size: 16px;
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 20px 0px;
      img {
        margin-right: 5px;
      }
    }
  }
}
