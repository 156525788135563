.gm-style-iw {
    background-color: rgba($color: white, $alpha: 0.95)!important;
    padding: 16px 18px!important;
    -webkit-box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px!important;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px!important;
    max-height: none!important;

    pointer-events: none!important;
    .gm-style-iw-d {
        overflow: auto!important;
        max-height: none!important;

        
    
    }
    .gm-ui-hover-effect {
            display: none!important;
        }
    
}

.gm-style-iw-tc {
    display: none!important;
}

