.active {
  a {
      color: #212121;
      font-weight: 500;
      font-size: 16px;
      border: none;
      &:hover {
        color: #2FB672;
        transition-duration: 0.5s;
      }
  }
}

.normal {
  a {
      border: none;
      color: white;
  }
}

.dropdown-menu {
  a {
      color: #343A40!important;
  }
}

.dropdown-country {
  position: relative;
}

.selected-country {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  height: 35px;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 100px;
  text-align: center;
  font-weight: 600;
  gap: 8px;
}

.options-country {
  position: absolute;
  top: 100%;
  left: 0;
  width: 105px;
  padding: 0;
  margin: 0;
  background-color: #fff;
  border: 1px solid #F8F8F8;
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
  border-radius: 40px;
  border-top: none;
  list-style: none;
  z-index: 100;
  padding: 10px 10px;

  & .country-option {
    height: 40px;
    padding: 8px;
    cursor: pointer;
    border-radius: 100px;
    color: #5F5F5F;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    &:hover {
      background: #F5F5F5;
    }

    &.selected {
      background: #F5F5F5;
    }
  }
}

.gg-chevron-down {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs,1));
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
  border-radius: 100px
 }
 
 .gg-chevron-down::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  left: 4px;
  top: 2px
 } 

 .button-auth {
    background-color: white;
    border: 1px solid #e0e0e0;  
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0.5rem;
    border-radius: 3rem;
    &:hover {
      box-shadow: 0 2px 4px rgba(0,0,0,0.18);
      transition-duration: 0.6s;
    }
 }

 .mobile-menu {
  .MuiDrawer-paperAnchorRight {
    width: 100%;
    border-radius: 0px!important;
  }
 }