.overlay-loading {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.5);
    z-index: 1;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}