.card-popular {
    height: 300px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 300ms;
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

    .content {
        box-sizing: border-box;
        width: 100%;
        position: absolute;
        padding: 30px 20px 20px 20px;
        height: auto;
        bottom: 0;
        background: linear-gradient(transparent, rgba(0, 0, 0, 0.6));
    }
}