.section_property {
    @media only screen and (min-width: 992px) {
        height: calc(100vh);
    }
    .content_property {
        scroll-behavior: smooth;

        @media screen and (min-width: 1007px) {
            z-index: 1;
            width: 375px;
            flex: 0 0 375px;
        }
        @media screen and (min-width: 1007px){
            position: relative;
            overflow-y: auto;
            overflow-x: hidden;
        }
        @media screen and (min-height: 400px) {
            position: relative;
            overflow-y: auto;
            overflow-x: hidden;
        }
        @media screen and (min-width: 1290px) {
            width: 750px;
            flex: 0 0 750px;
        }

        .container-results {
            width: 100%;
            display: grid;
            gap: 25px;
            grid-template-columns: repeat(auto-fill, minmax(286px, 1fr));
            margin-bottom: 8px;
            padding: 0px 20px;


            .custom_div {
                width: 100%;
                background-color: #13492E;
                box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
                border-radius: 10px;
                grid-column: 1 / -1; /* Esto hace que el div ocupe todo el ancho del contenedor */
              }
        }
        
    }

    .custom_div {
        width: 100%;
        background-color: #13492E;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
        border-radius: 10px;
        grid-column: 1 / -1; /* Esto hace que el div ocupe todo el ancho del contenedor */
      }

    

    .content_map {
        @media only screen and (min-width: 992px) {
            flex: 1;
        }
        @media (max-width: 768px) {
            height: calc(100vh - 116px);
            position: fixed;
            align-items: center;
            justify-content: center;
        }
        @media (max-width: 768px) {
            width: 100%;
        }
        .map {
            @media (max-width: 768px) {
                width: 100%;
                height: 100%;
                z-index: 1198;
                overflow: hidden;
                position: relative;
                flex-grow: 1;
                border-radius: 0;
            }
        }
    }
    .container-box {
        .content {
            flex: 1 0 auto;
            background-color: red;
            padding: 16px 0px;
            background-color: white;

            @media (max-width: 1300px) {
                padding: 10px 48px;
            }
            @media (max-width: 1100px) {
                padding: 0px 16px;
            }
            @media (max-width: 768px) {
                padding: 16px 10px;
            }
        }
    }
    
}

.mobile-section {
    .content_map {
        .map {
            height: calc(100vh - 116px);
        }
    }
}

.switch-btn {
    z-index: 1000;
    position: fixed;
    -webkit-transform: translate(-50%,10px);
    -ms-transform: translate(-50%,10px);
    transform: translate(-50%,10px);
    left: 50%;
    bottom: 6%;
    background-color: #212121;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    padding: 10px 45px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
        margin-left: 7px;
        width: 20px;
    }

    @media (min-width: 1024px) {
        display: none;
    }
}

.modal {
    position: fixed;
    z-index: 10000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    animation-name: animateFade;
    animation-duration: 0.2s;
    background-color: rgba(0, 0, 0, 0.5);
    @media (max-width: 768px) {
        position: fixed;

    }
    .modal_content {
        max-width: 500px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: relative;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
        @media (max-width: 768px) {
            padding: 0px 10px;
        }
        .modal_body {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            @media (max-width: 768px) {
                height: 500px;
                overflow-y: auto;
            }
            @media (min-height: 600px) {
                height: 570px;
                overflow-y: auto;
            }

            @media (min-height:1000px) {
                height: auto;
            } 
        }
    }
}

.form-radio {
    label {
        border-radius: 114px;
        border: 1px solid transparent;
        background-color: #EEEEEE;
        color: #8F8F8F;
    }
    input:checked+label {
        background: #ACE2C7;
        color: #1C6D44;
        border: none;
    }
    input {
        display: none;
    }
    .circle {
        label {
            border-radius: 114px;
            border: 1px solid transparent;
            background-color: #EEEEEE;
            color: #8F8F8F;
        }
        input:checked+label {
            background: #ACE2C7;
            color: #1C6D44;
            border: none;
        }
        input {
            display: none;
        }
    }
}


/* For pdf */

.react-pdf__Page {
    // margin-top: 10px;
    width: auto;
}

.react-pdf__Page__textContent {
    border: 1px solid darkgrey;
    box-shadow: 5px 5px 5px 1px #ccc;
    border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
    padding: 20px;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
}


/* For all pages */

.all-page-container {
    height: 100%;
    width: 100%;
    max-height: 600px;
    overflow: auto;
}


/* For signature input */

.signatureCanvas {
    border: 1px solid darkgrey;
    width: 100%;
    min-height: 150px;
    border-radius: 5px;
}

.tf-v1-popover-button {
    @media (max-width: 480px) {
        height: 55px!important;
        width: 55px!important;
        bottom: 20px!important;
        right: 15px!important;
    } 
}

.tf-v1-popover-button-icon {
    text-transform: uppercase;
    color: white;
    font-size: 40px!important;
    svg {
        path {
           fill: white;
        }
    }  
}

.select-order-market {
    .dropdown {
        position: relative;
        display: inline-block;
        height: 100%;
      }
      
      .dropdown-selected {
        height: 100%;
        display: flex;
        width: 150px;
        cursor: pointer;
        color: #212121;
        font-size: 16px;
        border-radius: 100px;
        text-align: left;
        
       
  
        
      }
  
      
      .dropdown-options {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        background-color: #fff;
        border: 1px solid #F8F8F8;
        box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
        border-radius: 40px;
        border-top: none;
        list-style: none;
        z-index: 10;
        padding: 10px 10px;
  
        @media (max-width: 768px) {
            width: 150px;
            margin-left: -55px;
        }
      
        & .dropdown-option {
          height: 40px;
          padding: 8px;
          cursor: pointer;
          border-radius: 100px;
          color: #5F5F5F;
          font-size: 14px;
      
          &:hover {
            background: #F5F5F5;
          }
      
          &.selected {
            background: #F5F5F5;
          }
        }
      }
  }