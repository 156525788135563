.country-page {
    background-image: url('https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/bg-selectCountry.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.country_option {
    border-radius: 24px;
    background-color: #13492E;
    width: 200px;
    height: 200px;
    display: flex;
    transition: transform .3s;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
    @media (max-width: 768px) {
        width: 100%;
        height: 70px;

        &:hover {
            transform: scale(1.05);
        }
    }
}