.main-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid #212121;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    @media (max-width: 768px) {
        font-size: 14px;
    }
    &:hover {
        background-color: #2FB672;
        transition-duration: 0.4s;
        color: white;
        border-color: #2FB672;
    }
    svg {
        margin-right: 8px;
    }
}