.popular-properties {
    @media (max-width: 1024px) {
    display: flex;
    align-items: stretch;
    flex-direction: row; 
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    width: auto;
    .card-p {
        width: 330px;
        max-width: 330px;
        flex-basis: 330px;
        flex-grow: 0;
        flex-shrink: 0;
    }
    }
}