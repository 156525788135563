.menu-link {
  text-transform: initial !important;
  color: #212121 !important;
  font-size: 16px;
  line-height: 24px !important;
  font-family: "Mena Grotesk" !important;

  &:hover {
    background-color: transparent !important;
  }
  &:focus {
    outline: none;
  }
  .gg-chevron-down {
    margin-left: 4px;
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 18px;
    height: 18px;
    border: 2px solid transparent;
    border-radius: 100px;
  }

  .gg-chevron-down::after {
    content: "";
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 10px;
    height: 10px;
    border-bottom: 2px solid #212121;
    border-right: 2px solid #212121;
    transform: rotate(45deg);
    left: 4px;
    top: 0px;
  }
}

.MuiPaper-root {
    box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.15)!important;
    border-radius: 8px!important;
    font-family: "Mena Grotesk" !important;
}

.MuiMenuItem-root {
    font-family: "Mena Grotesk" !important; 
    padding: 0px!important;
}
