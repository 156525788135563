.responsive-table {
    li {
      border-radius: 3px;
      padding: 25px 30px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }
    .table-header {
        background: #DDDEE4;
        /* Gray - Dark/200 */
        
        border: 1px solid #A8AABE;
        /* Shadows/Down/MD */
        
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
      font-size: 14px;
      letter-spacing: 0.03em;
      color: #252B5C;
      font-weight: 600;
    }
    .table-row {
      background-color: #ffffff;
      border: 1px solid #DEE8D8;
      border-radius: 5px;
      height: 100px;
      padding: 0px;


      .col {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: #5F5F5F;
      }
    }
    .col-1 {
      flex-basis: 10%;
    }
    .col-2 {
      flex-basis: 40%;
    }
    .col-3 {
      flex-basis: 25%;
    }
    .col-4 {
      flex-basis: 25%;
    }
    
    @media all and (max-width: 767px) {
      .table-header {
        display: none;
      }
      .table-row{
        display: none;
      }
      li {
        display: block;
      }
      .col {
        
        flex-basis: 100%;
        
      }
      .col {
        display: flex;
        padding: 10px 0;
        &:before {
          color: #6C7A89;
          padding-right: 10px;
          content: attr(data-label);
          flex-basis: 50%;
          text-align: right;
        }
      }
    }
  }