.ownerPage {
}
.referrals-header {
    background-image: url('https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/header-referrals.png');
    border-radius: 30px;
    @media (max-width: 788px) {
        background-image: url('https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/header-referrals-mobile.png');
        border-radius: 0px;
    }
    h1 {
        font-size: 80px;
        line-height: 80px;
        @media (max-width: 788px) { 
            font-size: 40px;
            line-height: 40px;
            margin-bottom: 10px;
            text-align: center;
        }
    }
}

.info-section {
    .one {
        left: 10%;
        position: absolute;
    }   
    .two {
        left: 15%;
        position: absolute;
    }
    .three {
        right: 15%;
        position: absolute;
    }
}