.customRadio {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  color: #212529;
  &[data-variation = "disabled" ] {
    opacity: 0.3;
  }
}

/* Hide the browser's default radio button */
.customRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.customRadio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.customRadio:hover input ~ .checkmark {
    background-color: #2FB672;
}

/* When the radio button is checked, add a blue background */
.customRadio input:checked ~ .checkmark {
    background-color: #2FB672;
    border: none;
}

.customRadio input:disabled ~ .checkmark {
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.25);
}




/* Create the indicator (the dot/circle - hidden when not checked) */
.customRadio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.customRadio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.customRadio .checkmark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}
