.loading-container {
    height: 100vh;
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    &[data-variation="md"] {
        height: auto;
    }
}