.dialog-price {
    position: absolute;
    margin-top: 20px;
    box-shadow: 0px 4px 40px 0px #6161614D;
    background-color: white;
    border-radius: 10px;
    height: auto;
    width: 26rem;
    left: 0px;
    top: 0px;
    transform: translate3d(0px, 38px, 0px);
    will-change: transform;
    z-index: 10;
    transition: max-height 0.05s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
    overflow: hidden;
    padding: 1.5rem;
}