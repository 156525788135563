.stepOneOffer {
    .react-tel-input .country-list {
        position: fixed!important;
        @media (max-width:1400px) {
            position: absolute!important;
        }
    }
}
.formOne {
    height: auto;
    @media(max-width: 1300px) {
        height: 380px;
    }
}
