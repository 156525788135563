.bidder-container {
    width: 460px;
    height: auto;
    border-radius: 20px;
}

.card-user {
    --main-color: #000;
    --submain-color: #78858F;
    --bg-color: #fff;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    width: 100%;
    height: 384px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background: var(--bg-color);
  }
  
  .card__img {
    height: 192px;
    width: 100%;
    background-color: #2FB672;
  }
  

  
  .card__avatar {
    position: absolute;
    width: 114px;
    height: 114px;
    background: var(--bg-color);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: calc(50% - 75px);
  }
  
  .card__avatar svg {
    width: 100px;
    height: 100px;
  }
  
  .card__title {
    margin-top: 60px;
  }
  
  .card__subtitle {
    margin-top: 0px;
  }
  
  .card__btn {
    margin-top: 15px;
    width: 76px;
    height: 31px;
    border: 2px solid var(--main-color);
    border-radius: 4px;
    font-weight: 700;
    font-size: 11px;
    color: var(--main-color);
    background: var(--bg-color);
    text-transform: uppercase;
    transition: all 0.3s;
  }
  
  .card__btn-solid {
    background: var(--main-color);
    color: var(--bg-color);
  }
  
  .card__btn:hover {
    background: var(--main-color);
    color: var(--bg-color);
  }
  
  .card__btn-solid:hover {
    background: var(--bg-color);
    color: var(--main-color);
  }

  .tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: start;
    margin-top: 1.75rem;

    @media (max-width: 768px) {
      justify-content: center;
    }
    gap: 10px;
    .tablink {
      background-color: white;
      border-radius: 30px;
      padding: 4px 20px;
      border: none;
      cursor: pointer;
      margin-bottom: 5px;
      font-weight: 500;
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    .tablink:hover {
      background-color: #212121;
      color: white;
      transition-duration: 0.6s;
    }
    
    .active {
      background-color: #212121;
      color: white;
    }
  }
  
  .tabcontent {
    display: none;
  }
  
  .tabcontent.active {
    display: block;
  }
  