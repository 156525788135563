.form-visit {
  max-height: 660px;
  overflow-y: auto;
}
.form-radio {
  label {
    border-radius: 114px;
    border: none;
    background-color: #eeeeee;
    color: #8f8f8f;
  }

  input:checked + label {
    background: #ace2c7;
    color: #1c6d44;
  }
  input:hover + label {
    background: #ace2c7;
    color: #1c6d44;
  }

  input {
    display: none;
    @media (max-width: 768px) {
      display: block !important;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
  input:disabled + label {
    opacity: 0.4;
    cursor: no-drop;
    pointer-events: none;
  }

  .circle {
    label {
      border-radius: 114px;
      border: 1px solid #252b5c;
      background-color: transparent;
      color: #252b5c;
    }

    input:checked + label {
      background: #ace2c7;
      color: #1c6d44;
      border: none;
    }

    input {
      display: none;
    }
  }
}

.form-radio-vertical-visit {
    label {
      min-width: 56px;
      height: 69px;
      box-shadow: rgba(108, 117, 125, 0.15) 0px 10px 25px;
      border-radius: 16px;
      background-color: transparent;
      color: #8f8f8f;
      margin: 3px;
      padding: 5px;
    }

    input:checked + label {
      background: #ace2c7;
      color: #1c6d44;
    }

    input:hover + label {
      background: #ace2c7;
      color: #1c6d44;
    }

    input {
      display: none;
      @media (max-width: 768px) {
        display: block !important;
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
      }
    }

        @media (max-width: 768px) {
            display: flex;
            align-items: stretch;
            flex-direction: row;
            flex-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            width: auto;
            gap: 10px;
        }
        .hour {
            @media (max-width: 768px) {
                width: 50px;
                max-width: 50px;
                flex-basis: 50px;
                flex-grow: 0;
                flex-shrink: 0;
            }
        }
    
  }
