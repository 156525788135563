$--vh: 9px;
.card_polygons {
    position: absolute;
    left: 24px;
    top: 24px;
    z-index: 10;
    width: 375px;
    height: calc(100vh - 175px);
    height: calc(var(--vh, 1vh) * 100 - 175px);
    background-color: #fafafa;
    box-shadow: 0 4px 10px #0003;
    backdrop-filter: blur(10px);
    border-radius: 16px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: transform .3s ease,top .3s ease,left .3s ease;
    will-change: transform;

    @media (max-height: 780px) {
        overflow: unset;
    }

    &_inner {
        height: 100%;
        padding-bottom: 24px;
        overflow-y: scroll;
        overflow-x: hidden;
        scrollbar-width: none;
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        overscroll-behavior: none;
        -webkit-overflow-scrolling: touch;

        .propi-sphere {
            position: absolute;
            right: -50px;
            top: 0;
            width: 40px;
            height: 40px;

            .logo {
                width: 100%;
                height: 100%;
                border-radius: 100%;
                transform: translate(0);
                overflow: hidden;
                box-sizing: border-box;
                cursor: pointer;

                .circle-wrap {
                    background-color: #1C6D44;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: -10%;
                    top: -10%;
                    filter: blur(2px);
                    animation: rotate_logo 4s linear infinite;

                    .circle {
                        border-radius: 50%;
                        position: absolute;
                        
                        &.c1 {
                            background-color: #13492E;
                            top: 0;
                            left: 0;
                            width: 50%;
                            height: 50%;
                            animation: rotate_logo 3s linear infinite;
                            transform-origin: bottom right;
                        }
                        &.c2 {
                            background-color: #1C6D44;
                            top: 0;
                            right: 0;
                            width: 60%;
                            height: 60%;
                            animation: rotate_logo 6s linear infinite;
                            transform-origin: bottom left;
                        }
                        &.c3 {
                            background-color: #26925B;
                            bottom: 0;
                            left: 50%;
                            width: 40%;
                            height: 40%;
                            animation: rotate_logo 7s linear infinite;
                            transform-origin: top left;
                        }
                        &.c4 {
                            background-color: #2FB672;
                            bottom: 0;
                            left: 0;
                            width: 60%;
                            height: 60%;
                            animation: rotate_logo 12s linear infinite;
                            transform-origin: bottom left;
                        }
                        &.c5 {
                            background-color: #59C58E;
                            top: 0;
                            left: 50%;
                            width: 50%;
                            height: 50%;
                            animation: rotate_logo 7s linear infinite;
                            transform-origin: bottom right;
                        }
                        &.c6 {
                            background-color: #82D3AA;
                            top: 0;
                            left: 0;
                            width: 50%;
                            height: 50%;
                            animation: rotate-abf30bc8 3s linear infinite;
                            transform-origin: bottom left;
                        }
                        &.c7 {
                            background-color: #ACE2C7;
                            bottom: 0;
                            left: 0;
                            width: 40%;
                            height: 40%;
                            animation: rotate-abf30bc8 4s linear infinite;
                            transform-origin: top left;
                        }
                    }

                    @keyframes rotate_logo {
                        to {
                            transform: rotate(360deg);
                        }
                    }

                }

                .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 40%;
                    transform: translate(-50%,-50%);
                    transition: transform .5s linear;
                }

                &:before {
                    content: "";
                    width: 120%;
                    height: 100%;
                    border-radius: 50%;
                    top: 30%;
                    background: rgba(0,0,0,.01);
                    position: absolute;
                    left: -10%;
                    right: 0;
                    z-index: 1;
                }
            }
        }
    }
    &_background {
        border-radius: 16px 16px 0 0;
        background-color: #fafafa;
        position: relative;
        z-index: 100;
        margin-top: 0;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 20px;
    }
}


.alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  .range-midprice {

    .bg-gradient-prices {
        background-image: linear-gradient(90deg, #D5F0E3 0, #ACE2C7 20%, #82D3AA 40%, #59C58E 60%, #2FB672 75%, #26925B 87%, #1C6D44 95%, #13492E 98%, #092417 100%)!important;
    }
}

.propi-sphere-normal {
    position: absolute;
    width: 40px;
    height: 40px;
    z-index: 1;

    .logo {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        transform: translate(0);
        overflow: hidden;
        box-sizing: border-box;
        cursor: pointer;

        .circle-wrap {
            background-color: #1C6D44;
            width: 100%;
            height: 100%;
            position: absolute;
            left: -10%;
            top: -10%;
            filter: blur(2px);
            animation: rotate_logo 4s linear infinite;

            .circle {
                border-radius: 50%;
                position: absolute;
                
                &.c1 {
                    background-color: #13492E;
                    top: 0;
                    left: 0;
                    width: 50%;
                    height: 50%;
                    animation: rotate_logo 3s linear infinite;
                    transform-origin: bottom right;
                }
                &.c2 {
                    background-color: #1C6D44;
                    top: 0;
                    right: 0;
                    width: 60%;
                    height: 60%;
                    animation: rotate_logo 6s linear infinite;
                    transform-origin: bottom left;
                }
                &.c3 {
                    background-color: #26925B;
                    bottom: 0;
                    left: 50%;
                    width: 40%;
                    height: 40%;
                    animation: rotate_logo 7s linear infinite;
                    transform-origin: top left;
                }
                &.c4 {
                    background-color: #2FB672;
                    bottom: 0;
                    left: 0;
                    width: 60%;
                    height: 60%;
                    animation: rotate_logo 12s linear infinite;
                    transform-origin: bottom left;
                }
                &.c5 {
                    background-color: #59C58E;
                    top: 0;
                    left: 50%;
                    width: 50%;
                    height: 50%;
                    animation: rotate_logo 7s linear infinite;
                    transform-origin: bottom right;
                }
                &.c6 {
                    background-color: #82D3AA;
                    top: 0;
                    left: 0;
                    width: 50%;
                    height: 50%;
                    animation: rotate-abf30bc8 3s linear infinite;
                    transform-origin: bottom left;
                }
                &.c7 {
                    background-color: #ACE2C7;
                    bottom: 0;
                    left: 0;
                    width: 40%;
                    height: 40%;
                    animation: rotate-abf30bc8 4s linear infinite;
                    transform-origin: top left;
                }
            }

            @keyframes rotate_logo {
                to {
                    transform: rotate(360deg);
                }
            }

        }

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40%;
            transform: translate(-50%,-50%);
            transition: transform .5s linear;
        }

        &:before {
            content: "";
            width: 120%;
            height: 100%;
            border-radius: 50%;
            top: 30%;
            background: rgba(0,0,0,.01);
            position: absolute;
            left: -10%;
            right: 0;
            z-index: 1;
        }
    }
}





  


