.content-main {
    background: #F2F6EF;
    border-radius: 30px;
}

.dropzone {
    background: #D5F0E3;
    border: 2px dashed #26925B;
    border-radius: 10px;
}

.checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    .checkmark {
        position: absolute;
        top: 7px;
        left: 6px;
        height: 16px;
        width: 16px;
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 4px;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
  }
  
  
  .checkbox:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  .checkbox input:checked ~ .checkmark {
    background-color: #2FB672;
    border: none;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox input:checked ~ .checkmark:after {
    display: block;
  }
  
  .checkbox .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2.5px 2.5px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }