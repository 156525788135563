.card-offer {
    background: #FFFFFF;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    border: 0.3px solid #e0e0e0;
    &[data-variation = 'sign'] {
        background-color: #252B5C;
        .user {
            color: white;
        }
        .text {
            color: white;
        }
    }
}