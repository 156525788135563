.cardPro {
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.option-booked {
    background-color: white;
    padding: 1rem 0.70rem;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 15px;
  h2 {
    font-weight: 600;
    color: #092417;
  }
  p {
    color: #5F5F5F;
    font-size: 12px;
  }

  &.selected {
    border: 2px solid #2fb672;
  }

  &.not-selected {
    border: 1px solid #E0E0E0;
  }

  .mark {
    border: 5px solid #2FB672;
  }

  .not-mark {
    border: 1px solid rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 768px) {
    width: 225px;
    max-width: 225px;
    flex-basis: 225px;
    flex-grow: 0;
    flex-shrink: 0;
  }
}

.cardBooked-container {
    display: flex;
    height: 600px;
    padding-right: 1rem;
    width: calc(100% + 0.70rem);
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 0;
    flex-direction: column;
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    @media (max-width: 768px) {
      height: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        width: auto;
        gap: 10px;
      }
}


.bidder-container-devs {
  width: 100%;
  max-width: 460px;
  height: auto;
  border-radius: 30px;
}


.tooltipDev {
  position: relative;
  display: inline-block;
}

.tooltipDev .tooltiptext {
  visibility: hidden;
  width: 100px;
  background-color: #212121;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  font-weight: 600;
  z-index: 1000000;
}

.tooltipDev .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #212121 transparent transparent transparent;
  z-index: 1000000;
  
}

.tooltipDev:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}