.card-property {
    border: 1px solid #E0E0E0;
    border-radius: 10px;
}

.MuiTabs-scroller {
    .MuiTabs-flexContainer {
        .MuiTab-root {
            color: #757575!important;
            text-transform: initial;
            font-family: "Mena Grotesk" !important;
            
            &.Mui-selected {
                color: #2FB672!important;
            }
        }

    }
}