.property-gallery-app {
    img {
        border-radius: 15px;
        cursor: pointer;
    }

    .overMorePhotos {
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 15px;
    }
}

.swiper-button-next, .swiper-button-prev{
    color: white!important;
    text-shadow: 2px 2px #000;

}



.swiper-pagination-bullet-active
{
    background-color: white!important;
    text-shadow: 2px 2px #000;
}
.swiper-slide img{
    width: 60%;
    margin: 0 auto;
}
@media only screen and (max-width: 1440px){
.swiper-slide img{
    max-width: 700px!important;
    margin: 0 auto;
}
}
@media only screen and (max-width: 600px){
    .swiper-slide img{
    max-width: 100%!important;
    width: 100%;
    margin: 0 auto;

}

    .swiper-button-next:after, .swiper-button-prev:after{
        font-size: 23px!important;
    }
    .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
      display:none;
    }
}

.sliderWrapImage {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    transition: all ease 1s;
    .close, .previous, .next {
        position: fixed;
        z-index: 9999;
    }
    .close {
        top: 40px;
        right: 40px;
    }
    .next {
        top: 50%;
        transform: translateY(-50%);
        right: 40px;

    }
    .previous {
        top: 50%;
        transform: translateY(-50%);
        left: 40px;
    }
    .sliderWrapImage {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 150px;

        @media (max-width: 768px) {
            padding: 25px;
        }

        img {
            max-width: 100%;
            max-height: 100%;
            pointer-events: none;
            -webkit-user-select: none;
            user-select: none;
            border-radius: 6px;
        }   
    }   
}

.property-gallery-mobile {
    position: relative;
    .main {
        height: 360px;

        img {
            border-radius: 15px;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        .options {
            bottom: 0;
            left: 5%;
        }
    } 
    .previous, .next {
        position: absolute;
        z-index: 1;
        height: 30px;
        width: 30px;
    }
    .next {
        top: 50%;
        transform: translateY(-50%);
        right: 20px;

    }
    .previous {
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
    }  
}