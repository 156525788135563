.loginDevs-container {
    .headerLogin {
        display: flex;
        align-items: flex-end;
        background-image: url('https://storage.googleapis.com/assets-us-east4-propilatam/pre-sales/projects/c7fe5de2-3923-43db-91b7-7b1e49af9ae0/cover/1705616068520.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
    }
}