.form-radio-filter {
    label {
        border: 1px solid #E0E0E0;
        background-color: transparent;
        color: #616161;
        font-size: 16px;
        &.left {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }
        &.right {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }

    input:checked+label {
        background: #ACE2C7;
        color: #1C6D44;
        
    }
    input:hover+label {
        background: #ACE2C7;
        color: #1C6D44;
        
    }
    input {
        display: none;
        @media (max-width: 768px) {
        display: block!important;
        position: absolute;
        height: 100%;
        opacity: 0;
        }
    }
    input:disabled+label{
        opacity: 0.4;
        cursor: no-drop;
        pointer-events: none;
    }

    .circle {
        label {
            border-radius: 114px;
            background-color: transparent;
        }
    
        input:checked+label {
            background: #ACE2C7;
            color: #1C6D44;
            border: none;
        }
    
        input {
            display: none;
        }
    }
}